<template>
  <div data-cy="asset-projects-page">
    <TabTitle class="mb-4" icon="fa-tasks">
      <template> Projects </template>
      <template #sub-title>A list of projects related to this asset</template>
    </TabTitle>
    <div class="row items-push mb-0">
      <div class="col-sm-4 col-xl-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input
            id="projects-search"
            v-model="search"
            type="text"
            class="form-control border-left-0"
            name="projects-search"
            placeholder="Search Projects.."
          />
        </div>
      </div>
      <div class="col-sm-4 col-xl-3">
        <select id="projects-filter" v-model="filter" class="custom-select" name="projects-filter">
          <option value="">All ({{ projects.length }})</option>
          <option value="active">Active ({{ projects.filter(p => p.status === 'active').length }})</option>
          <option value="pending">Pending ({{ projects.filter(p => p.status === 'pending').length }})</option>
          <option value="completed">Completed ({{ projects.filter(p => p.status === 'completed').length }})</option>
        </select>
      </div>
      <div class="col-sm-4 col-xl-5 text-right">
        <router-link
          v-if="$permissions.write('asset', asset)"
          class="btn btn-primary"
          data-cy="new-project-btn"
          :to="{ name: 'asset-projects-create' }"
          >New Project</router-link
        >
      </div>
    </div>
    <div v-if="filteredProjects.length" class="row row-deck">
      <div v-for="project in filteredProjects" :key="project._id" class="col-md-6 col-xl-4">
        <div class="block block-rounded block-border">
          <div class="block-header">
            <div
              class="flex-fill font-size-sm font-w600"
              :class="{ 'text-muted': new Date(project.startDate) > Date.now(), 'text-warning': new Date(project.startDate) < Date.now() }"
            >
              <i class="fa fa-clock mr-1"></i> {{ project.startDate ? project.startDate : 'No date set' | date('Do MMM YYYY') }}
            </div>
            <div class="block-options">
              <div class="dropdown">
                <button
                  v-if="$permissions.write('asset', asset)"
                  type="button"
                  class="btn-block-option"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <router-link
                    v-if="$permissions.write('asset', asset)"
                    class="dropdown-item"
                    :to="{ name: 'asset-projects-edit', params: { projectId: project._id } }"
                    ><i class="fa fa-fw fa-pencil-alt mr-1"></i> Edit Project
                  </router-link>
                  <div role="separator" class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    href="#"
                    @click.prevent="
                      () => {
                        modals.remove = project;
                      }
                    "
                  >
                    <i class="fa fa-fw fa-trash mr-1"></i> Delete Project
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="block-content bg-body-light text-center px-4">
            <h3 class="font-size-h4 font-w700 mb-1">
              <router-link :to="{ name: 'asset-projects-view', params: { projectId: project._id } }">{{ project.name }}</router-link>
            </h3>
            <h4 class="font-size-h6 text-muted mb-3">{{ project.description }}</h4>
            <div class="progress push" style="height: 10px">
              <div class="progress-bar" :style="{ width: `${project.progress}%` }">
                <!-- <span class="font-size-sm font-w600">{{ project.progress }}%</span> -->
              </div>
            </div>
            <div class="push">
              <span
                class="badge text-uppercase font-w700 py-2 px-3"
                :class="{
                  'badge-success': project.status === 'completed',
                  'badge-warning': project.status === 'active',
                  'badge-secondary': project.status === 'pending'
                }"
                >{{ project.status }}</span
              >
            </div>
          </div>
          <div class="block-content block-content-full">
            <div class="row">
              <div class="col-md">
                <router-link
                  class="btn btn-block btn-primary"
                  :data-cy="project._id + '-view'"
                  :to="{ name: 'asset-projects-view', params: { projectId: project._id } }"
                >
                  <i class="fa fa-search mr-1 opacity-50"></i> View
                </router-link>
              </div>
              <div v-if="$permissions.write('asset', asset)" class="col-md">
                <router-link
                  class="btn btn-block btn-alt-secondary"
                  :data-cy="project._id + '-edit'"
                  :to="{ name: 'asset-projects-edit', params: { projectId: project._id } }"
                >
                  <i class="fa fa-pencil mr-1 opacity-50"></i> Edit
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning">No projects found with the selected filters.</div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Project"
      :text="`Please confirm you would like to remove project: <strong>${modals.remove.name}</strong>`"
      @close="
        () => {
          modals.remove = false;
        }
      "
      @submit="onRemove"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetProjectList',
  components: {
    ConfirmModal,
    TabTitle
  },
  data() {
    return {
      modals: {
        remove: false
      },
      filter: '',
      search: ''
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      projects: 'asset/projects'
    }),
    filteredProjects() {
      let projects;

      if (!this.filter && !this.search) {
        projects = this.projects.map(p => ({
          ...p,
          progress: (((p.tasks || []).filter(t => t.status === 'completed').length / (p.tasks || []).length) * 100).toFixed(0)
        }));
      } else {
        projects = this.projects
          .filter(p => !this.filter || p.status === this.filter)
          .filter(p => !this.search || p.name.toLowerCase().includes(this.search.toLowerCase()))
          .map(p => ({
            ...p,
            progress: (((p.tasks || []).filter(t => t.status === 'completed').length / (p.tasks || []).length) * 100).toFixed(0)
          }));
      }

      projects.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

      return projects;
    }
  },
  mounted() {
    this.listProjects({ params: { assetId: this.asset._id } });
  },
  methods: {
    ...mapActions({
      deleteProject: 'asset/deleteProject',
      listProjects: 'asset/listProjects'
    }),
    async onRemove() {
      try {
        const deleteProject = await this.deleteProject({ id: this.modals.remove._id });

        if (deleteProject) {
          this.$router.push({ name: 'asset-projects-list' });
          this.$toasted.success('Project deleted successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not delete project.');
      }

      this.modals.remove = false;
    }
  }
};
</script>
